<script>
import { uploadTimekeeperEmployeeList, timekeeperTemplateDownload } from "@/services/api/requests"

export default {
	name: "DialogTimekeeperUpload",
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dialog: false,
			submitting: false,
			file: null,
			formValid: false,
			downloading: false,
			documentName: "Шаблон_отпусков.xlsx",
		}
	},
	computed: {
		fileRules() {
			return [
				v => !!v || "Выберете файл",
				v => v && v.size < 2000000 || "Размер файла должен быть меньше 2МБ",
				v => v && v.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "Файл должен быть в формате .xlsx"
			]
		},
	},
	methods: {
		onCloseDialog() {
			this.dialog = false
		},
		async onSubmit() {
			this.submitting = true
			try {
				const formData = new FormData()
				formData.append("file", this.file)
				await uploadTimekeeperEmployeeList(formData)
				this.$emit("update")
			} catch (e) {
				console.error(e)
			} finally {
				this.submitting = false
				this.dialog = false
			}
		},
		async onDownloadTemplate() {
			try {
				this.downloading = true
				const elem = await timekeeperTemplateDownload()
				elem.download = this.documentName
				elem.click()
			} catch (e) {
				console.error(e)
			} finally {
				this.downloading = false
			}
		},
	}
}
</script>

<template>
	<v-dialog
		v-model="dialog"
		max-width="500px"
		persistent
	>
		<template #activator="{on, attrs}">
			<v-btn
				v-bind="attrs"
				color="primary"
				depressed
				:disabled="disabled"
				rounded
				v-on="on"
			>
				Загрузить шаблон
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				Загрузка отпусков
			</v-card-title>
			<v-card-subtitle class="mt-3">
				Приложите заполненный шаблон со списком сотрудников и их отпусков
			</v-card-subtitle>
			<v-card-text>
				Скачать шаблон можно здесь:
				<v-btn
					x-small
					depressed
					rounded
					color="primary"
					:disabled="submitting"
					:loading="downloading"
					@click="onDownloadTemplate"
				>
					Скачать шаблон
				</v-btn>
				<v-form
					v-model="formValid"
					@submit.prevent
				>
					<v-file-input
						v-model="file"
						label="Выберите файл"
						:rules="fileRules"
						clearable
						outlined
						class="mt-3"
						:disabled="submitting"
						accept=".xlsx"
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-row class="ma-0">
					<v-col
						cols="12"
						sm="6"
						order="1"
						order-sm="0"
					>
						<v-btn
							rounded
							block
							:disabled="submitting"
							depressed
							@click="onCloseDialog"
						>
							Закрыть
						</v-btn>
					</v-col>
					<v-col
						cols="12"
						sm="6"
					>
						<v-btn
							rounded
							block
							depressed
							color="primary"
							:disabled="!formValid"
							:loading="submitting"
							@click="onSubmit"
						>
							Отправить
						</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style scoped lang="scss">

</style>