<script>
import {mdiHelpCircleOutline} from "@mdi/js"

export default {
	name: "InputAdditionalVacation",
	props: {
		value: {
			type: Boolean,
			required: true
		}
	},
	data: () => ({
		text: "Если для вашей должности предусмотрен дополнительный отпуск, спланируйте также и его, либо заполните заявление на компенсацию в конце года.",
		icons: {
			help: mdiHelpCircleOutline
		}
	}),
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			}
		}
	},
}
</script>

<template>
	<v-checkbox
		v-model="model"
		dense
		hide-details
		class="px-6 pb-1"
	>
		<template #label>
			Дополнительный отпуск?
			<v-tooltip
				bottom
				:max-width="250"
				:offset-y="true"
			>
				<template #activator="{ on, attrs }">
					<v-icon
						color="primary"
						small
						class="ml-1"
						v-bind="attrs"
						v-on="on"
					>
						{{ icons.help }}
					</v-icon>
				</template>
				<span
					v-text="text"
				/>
			</v-tooltip>
		</template>
	</v-checkbox>
</template>