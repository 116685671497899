<script>
import {dictStatuses, getStatusColor} from "@/services/mappers"

export default {
	name: "BtnApplicationReschedule",
	props: {
		status: {
			type: String,
			required: true,
		},
		block: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dictStatuses,
		}
	},
	methods: {getStatusColor},
}
</script>

<template>
	<v-col
		v-if="status	=== dictStatuses.ACCEPTED || status === dictStatuses.REJECTED_RESCHEDULED"
		cols="12"
		sm="auto"
	>
		<v-btn
			rounded
			depressed
			:block="block"
			:color="getStatusColor(dictStatuses.RESCHEDULED)"
			class="px-6"
			@click="$emit('reschedule')"
		>
			Перенести
		</v-btn>
	</v-col>
</template>