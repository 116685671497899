<script>
import {dictStatuses} from "@/services/mappers"

export default {
	name: "BtnApplicationEdit",
	props: {
		status: {
			type: String,
			required: true,
		},
		block: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dictStatuses,
		}
	},
}
</script>

<template>
	<v-col
		v-if="status	=== dictStatuses.REJECTED || status	=== dictStatuses.NEW"
		cols="12"
		sm="auto"
	>
		<v-btn
			rounded
			depressed
			:disabled="disabled"
			:block="block"
			:color="status	=== dictStatuses.REJECTED ? 'primary' : ''"
			class="px-6"
			@click="$emit('edit')"
		>
			Редактировать
		</v-btn>
	</v-col>
</template>