<script>
import {dictStatuses} from "@/services/mappers"
import {putVacationApplication} from "@/services/api/requests"

export default {
	name: "BtnApplicationCancel",
	props: {
		application: {
			type: Object,
			required: true,
		},
		year: {
			type: Number,
			required: true,
		},
		block: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dictStatuses,
		}
	},
	methods: {
		async deleteApplication(application) {
			await putVacationApplication(application.id, { ...application, is_deleted: true})
			this.$emit("update")
		},
	},
}
</script>

<template>
	<v-col
		v-if="application.status	=== dictStatuses.NEW"
		cols="12"
		sm="auto"
	>
		<v-btn
			rounded
			:block="block"
			depressed
			class="px-6"
			@click="deleteApplication(application)"
		>
			Отменить заявку
		</v-btn>
	</v-col>
</template>