<script>
import DialogWrapper from "@/components/Dialog/DialogWrapper.vue";
import {mdiFileOutline} from "@mdi/js";
import {getVacationApplicationRescheduleDoc} from "@/services/api/requests";
import {dictStatuses} from "@/services/mappers";
export default {
	name: "BtnRescheduleDocument",
	components: {DialogWrapper},
	props: {
		status: {
			type: String,
			required: true,
		},
		block: {
			type: Boolean,
			default: false,
		},
		id: {
			type: Number,
			required: true,
		},
		preopen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			icons: {
				doc: mdiFileOutline
			},
		}
	},
	computed: {
		dictStatuses() {
			return dictStatuses
		}
	},
	methods: {
		async onDownload() {
			try {
				this.loading = true
				const elem = await getVacationApplicationRescheduleDoc(this.id)
				elem.download = "Заявление на перенос отпуска.xlsx"
				elem.click()
			} catch (e) {
				console.error(e)
			} finally {
				this.loading = false
			}
		},
	}
}
</script>

<template>
	<v-col
		v-if="status	=== dictStatuses.RESCHEDULED"
		cols="12"
		sm="auto"
	>
		<DialogWrapper
			title="Заявление на перенос отпуска"
			:preopen="preopen"
			:disabled="loading"
		>
			<template #activator="{attrs, on}">
				<v-btn
					rounded
					depressed
					outlined
					color="info"
					:block="block"
					class="px-6"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon class="mr-1">
						{{ icons.doc }}
					</v-icon>
					Скачать заявление
				</v-btn>
			</template>
			<template #text>
				<v-alert>
					Для переноса отпуска необходимо скачать заявление и подписать его у руководителя
				</v-alert>
			</template>
			<template #btn>
				<v-btn
					rounded
					color="primary"
					block
					:loading="loading"
					depressed
					@click="onDownload"
				>
					Скачать
				</v-btn>
			</template>
		</DialogWrapper>
	</v-col>
</template>

<style scoped lang="scss">

</style>