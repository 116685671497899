<script>
import {
	mdiCheckCircle,
	mdiCloseCircleOutline,
	mdiDeleteOutline,
	mdiInformationOutline,
	mdiPlus
} from "@mdi/js"
import {dictStatuses, dictVacationTypes} from "@/services/mappers"
import {countDays, getDaysTitle, getDisplayDate} from "@/services/helpers"
import {postVacationApplication, putVacationApplication, rescheduleVacationApplication} from "@/services/api/requests"
import InputAdditionalVacation from "@/components/Inputs/InputAdditionalVacation.vue"
import CardUser from "@/components/Card/CardUser.vue"

export default {
	name: "DialogApplication",
	components: {CardUser, InputAdditionalVacation},
	model: {
		prop: "dialog",
		event: "toggleDialog",
	},
	props: {
		dialog: {
			type: Boolean,
			required: true,
		},
		currentYear: {
			type: Number,
			required: true,
		},
		additionalDaysAvailable: {
			type: Number,
			required: true,
		},
		daysAvailable: {
			type: Number,
			required: true,
		},
		holidays: {
			type: Array,
			default: () => [],
		},
		mode: {
			type: String,
			default: 'create',
			validate(value) {
				return ['create', 'edit', 'reschedule'].includes(value)
			}
		},
		applicationEdit: {
			type: Object,
			default: () => null,
		},
		employeeId: {
			type: Number,
			default: -1,
		},
		employee: {
			type: Object,
			default: () => null,
		},
	},
	data() {
		return {
			dictStatuses,
			icons: {
				plus: mdiPlus,
				done: mdiCheckCircle,
				delete: mdiDeleteOutline,
				notDone: mdiCloseCircleOutline,
				help: mdiInformationOutline,
			},
			application: {
				vacations: [],
				year: null,
			},
			loadingRanges: false,
			newRange: [],
			isNewRangeAdditional: false,
			editedRange: [],
			editedIsAdditional: false,
			editedRangeId: null,
			showDatePicker: false,
			submitting: false,
			displayedDateOnCreateDatePicker: null,
		}
	},
	computed: {
		dictVacationTypes() {
			return dictVacationTypes
		},
		modelDialog: {
			get() {
				return this.dialog
			},
			set(value) {
				this.$emit("toggleDialog", value)
			}
		},
		countMain() {
			return this.application.vacations
				.filter(v => v.vacation_type === dictVacationTypes.MAIN)
				.reduce((acc, v) => acc + v.count, 0)
		},
		countAdditional() {
			return this.application.vacations
				.filter(v => v.vacation_type === dictVacationTypes.ADDITIONAL)
				.reduce((acc, v) => acc + v.count, 0)
		},
		checkAllDays() {
			return this.countMain === this.daysAvailable
		},
		check14Days() {
			return this.application.vacations
				.filter(v => v.vacation_type === dictVacationTypes.MAIN)
				.some(v => v.count >= 14)
		},
		checkAdditional() {
			return this.countAdditional === this.additionalDaysAvailable
		},
		checkDifference() {
			const oldVs = this.applicationEdit.vacations
			const newVs = this.application.vacations
			if (oldVs.length !== newVs.length) return true
			for (let i = 0; i < oldVs.length; i++) {
				const vOld = oldVs[i]
				const vNew = newVs[i]
				if (vOld.start_date !== vNew.start_date) return true
				if (vOld.end_date !== vNew.end_date) return true
			}
			return false
		},
		submitPossible() {
			const rules = this.check14Days && this.checkAllDays && this.checkAdditional
			if (['edit', 'reschedule'].includes(this.mode))
				return rules && this.checkDifference
			return rules
		},
		daysRemain(){
			return this.daysAvailable - this.countMain
		},
		additionalDaysRemain(){
			return this.additionalDaysAvailable - this.countAdditional
		},
		daysRemainText(){
			let days = Math.abs(this.daysRemain)

			if(days === 1){
				return 'день';
			}
			if (days >= 2 && days <= 4) {
				return 'дня';
			}
			else {
				return 'дней';
			}
		},
		additionalDaysRemainText(){
			let days = Math.abs(this.additionalDaysRemain)

			if(days === 1){
				return 'день';
			}
			if (days >= 2 && days <= 4) {
				return 'дня';
			}
			else {
				return 'дней';
			}
		},
		btnText() {
			switch (this.mode) {
				case 'create':
					return 'Отправить'
				case 'edit':
					return 'Редактировать'
				case 'reschedule':
					return 'Перенести'
				default:
					return 'Закрыть'
			}
		}
	},
	watch: {
		showDatePicker(value) {
			if (value) {
				setTimeout(() => {
					const el = document.getElementById("dialog-application-scroll-area")
					if (el) el.scrollTo({
						top: el.scrollHeight,
						behavior: 'smooth'
					})
				}, 300)
			}
		},
		modelDialog(value) {
			if (value) {
				this.init()
			}
		}
	},
	methods: {
		init() {
			this.displayedDateOnCreateDatePicker = `${this.currentYear}-01`
			this.application.year = this.currentYear
			if (this.mode === 'create') {
				this.application.employee = this.employeeId > 0 ? this.employeeId : undefined
			}
			if (['edit', 'reschedule'].includes(this.mode)) {
				this.application.id = this.applicationEdit.id
				this.application.vacations = this.applicationEdit.vacations.map(v => {
					return {
						...v,
						count: countDays(v.start_date, v.end_date, this.holidays),
					}
				})
			}
		},
		getDaysTitle,
		getDisplayDate,
		countDays,
		async submit() {
			this.submitting = true
			try {
				switch (this.mode) {
					case 'create':
						await postVacationApplication(this.application)
						break
					case 'edit':
						await putVacationApplication(this.application.id, this.application)
						break
					case 'reschedule':
						await rescheduleVacationApplication(this.application.id, this.application)
						break
				}
				this.$emit("update")
				this.modelDialog = false
			} catch (e) {
				console.error(e)
			}
			this.submitting = false
		},
		async onEditor(payload, i) {
			this.loadingRanges = true
			if (payload) this.openEditRange(i)
			else await this.closeEditRange(i)
			this.loadingRanges = false
		},
		openEditRange(i) {
			this.editedRangeId = i
			const v = this.application.vacations[i]
			this.editedRange = [v.start_date, v.end_date]
			this.editedIsAdditional = v.vacation_type === dictVacationTypes.ADDITIONAL
		},
		async closeEditRange(i) {
			return new Promise((resolve) => {
				if (!this.editedRange || !this.editedRange[0]) {
					this.editedRange = null
					this.editedRangeId = null
					this.editedIsAdditional = false
					return
				}
				if (!this.editedRange[1]) this.editedRange[1] = this.editedRange[0]
				const v = this.application.vacations[i]
				const e0 = this.editedRange[0]
				const e1 = this.editedRange[1]
				if (new Date(e0) < new Date(e1)) {
					v.start_date = e0
					v.end_date = e1
				} else {
					v.start_date = e1
					v.end_date = e0
				}
				v.vacation_type = this.editedIsAdditional ? dictVacationTypes.ADDITIONAL : dictVacationTypes.MAIN
				v.count = this.countDays(v.start_date, v.end_date, this.holidays)
				this.editedRange = null
				this.editedRangeId = null
				this.editedIsAdditional = false
				setTimeout(() => {
					this.checkInclusion(i)
					resolve()
				}, 300)
			})
		},
		checkInclusion(i) {
			const vacations = this.application.vacations
			if (i === undefined) i = vacations.length - 1
			const start = new Date(vacations[i].start_date)
			const end = new Date(vacations[i].end_date)
			const idsToExclude = []
			for (let i = 0; i < vacations.length; i++) {
				const v = vacations[i]
				const vStart = new Date(v.start_date)
				const vEnd = new Date(v.end_date)
				if (start < vStart && vEnd < end)
					idsToExclude.push(i)
			}
			this.application.vacations = vacations.filter((v, i) => !idsToExclude.includes(i))
		},
		addRange(range) {
			if (!range || !range[0]) return
			if (!range[1]) range[1] = range[0]
			let start_date = null, end_date = null
			if (new Date(range[0]) < new Date(range[1])) {
				start_date = range[0]
				end_date = range[1]
			} else {
				start_date = range[1]
				end_date = range[0]
			}
			const count = this.countDays(start_date, end_date, this.holidays)
			this.application.vacations.push({
				start_date,
				end_date,
				count,
				vacation_type: this.isNewRangeAdditional ? dictVacationTypes.ADDITIONAL : dictVacationTypes.MAIN,
			})
			setTimeout(this.checkInclusion, 300)
			this.newRange = null
			this.showDatePicker = false
			this.isNewRangeAdditional = false
		},
		async deleteRange(i) {
			this.loadingRanges = true
			await this.closeEditRange(i)
			this.application.vacations.splice(i, 1)
			this.loadingRanges = false
		},
		checkAvailability(excludeIndex=-1) {
			return (date) => {
				const d = new Date(date)
				const vacations = this.application.vacations
				for (let i = 0; i < vacations.length; i++) {
					if (i === excludeIndex) continue
					const start = new Date(vacations[i].start_date)
					const end = new Date(vacations[i].end_date)
					if (d >= start && d <= end) return false
				}
				return true
			}
		},
	}
}
</script>

<template>
	<v-dialog
		v-model="modelDialog"
		persistent
		scrollable
		max-width="500"
	>
		<v-card>
			<v-card-title>
				<div
					v-if="employee && employeeId > 0"
					class="d-flex align-center"
					style="gap: 8px"
				>
					<h3>Заявка за</h3>
					<CardUser
						:user="employee"
						label
						short
						small
					/>
				</div>
				<h3 v-else>
					Заявка на отпуск
				</h3>
			</v-card-title>
			<v-card-subtitle class="pb-2 pt-1">
				Добавьте дни отпуска выбирая даты в календаре. Следующие правила должны быть соблюдены:
				<div class="d-flex align-center my-1">
					<v-icon
						v-if="checkAllDays"
						color="success"
					>
						{{ icons.done }}
					</v-icon>
					<v-icon v-else>
						{{ icons.notDone }}
					</v-icon>
					<span
						class="pl-2"
					>
						<template v-if="daysRemain > 0">
							Осталось выбрать
							<strong>{{ `${daysRemain} ${daysRemainText}` }}</strong>
							основного отпуска
						</template>
						<template v-else-if="daysRemain === 0">
							Выбрано необходимое кол-во дней основного отпуска
						</template>
						<template v-else>
							Выбранный основной отпуск превышает лимит на
							<strong>{{ `${Math.abs(daysRemain)} ${daysRemainText}` }}</strong>
						</template>
					</span>
				</div>
				<div class="d-flex align-center">
					<v-icon
						v-if="check14Days"
						color="success"
					>
						{{ icons.done }}
					</v-icon>
					<v-icon v-else>
						{{ icons.notDone }}
					</v-icon>
					<span class="pl-2">
						Хотя бы один период основного отпуска должен быть не менее
						<strong>14 дней</strong>
					</span>
				</div>
				<div class="d-flex align-center my-1">
					<v-icon
						v-if="checkAdditional"
						color="success"
					>
						{{ icons.done }}
					</v-icon>
					<v-icon v-else>
						{{ icons.notDone }}
					</v-icon>
					<span
						class="pl-2"
					>
						<template v-if="additionalDaysRemain > 0">
							Осталось выбрать
							<strong>{{ `${additionalDaysRemain} ${additionalDaysRemainText}` }}</strong>
							дополнительного отпуска
						</template>
						<template v-else-if="additionalDaysRemain === 0">
							Выбрано необходимое кол-во дней дополнительного отпуска
						</template>
						<template v-else>
							Выбранный дополнительный отпуск превышает лимит на
							<strong>{{ `${Math.abs(additionalDaysRemain)} ${additionalDaysRemainText}` }}</strong>
						</template>
					</span>
				</div>
			</v-card-subtitle>
			<v-card-text
				id="dialog-application-scroll-area"
				style="height: 600px"
				class="pb-0"
			>
				<v-list
					:disabled="submitting || loadingRanges"
					class="py-0"
					dense
				>
					<v-list-group
						v-for="({
							id,
							start_date: startDate,
							end_date: endDate,
							count = countDays(startDate, endDate, holidays),
							vacation_type: vacationType
						}, i) in application.vacations"
						:key="`${id}_${i}`"
						:disabled="editedRangeId !== null && i !== editedRangeId"
						:ripple="editedRangeId === null || i === editedRangeId"
						:class="{opened_list_group: i === editedRangeId}"
						@input="onEditor($event, i)"
					>
						<template #activator>
							<v-list-item-avatar class="mr-1">
								<v-hover
									v-slot="{hover}"
									:disabled="editedRangeId == null || i !== editedRangeId"
								>
									<v-avatar v-if="hover">
										<v-icon
											large
											color="warning"
											@click="deleteRange(i)"
										>
											{{ icons.delete }}
										</v-icon>
									</v-avatar>
									<v-avatar
										v-else
										color="secondary"
									>
										<span style="color: white">
											{{ i + 1 }}
										</span>
									</v-avatar>
								</v-hover>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title class="pl-1">
									{{ getDisplayDate(startDate, endDate) }}
									<v-chip
										small
										:color="vacationType === dictVacationTypes.MAIN ? 'primary' : ''"
										label
										outlined
									>
										{{ count }} {{ getDaysTitle(count) }}
									</v-chip>
								</v-list-item-title>
								<v-list-item-subtitle
									v-if="vacationType === dictVacationTypes.ADDITIONAL"
									class="font-italic pl-1"
								>
									Дополнительный отпуск
								</v-list-item-subtitle>
							</v-list-item-content>
						</template>
						<v-divider />
						<InputAdditionalVacation v-model="editedIsAdditional" />
						<v-date-picker
							v-model="editedRange"
							range
							no-title
							:min="`${currentYear}-01-01`"
							:max="`${currentYear}-12-31`"
							width="100%"
							:first-day-of-week="1"
							show-adjacent-months
							:events="holidays"
							:color="editedIsAdditional ? 'grey' : 'primary'"
							event-color="secondary"
							:allowed-dates="checkAvailability(i)"
						/>
					</v-list-group>
					<v-list-group
						v-model="showDatePicker"
						:class="{opened_list_group: showDatePicker}"
					>
						<template #activator>
							<v-list-item-avatar>
								<v-icon>{{ icons.plus }}</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>
									Добавить период
								</v-list-item-title>
							</v-list-item-content>
						</template>
						<v-divider />
						<InputAdditionalVacation v-model="isNewRangeAdditional" />
						<v-date-picker
							v-model="newRange"
							range
							no-title
							:min="`${currentYear}-01-01`"
							:max="`${currentYear}-12-31`"
							width="100%"
							:first-day-of-week="1"
							show-adjacent-months
							:events="holidays"
							event-color="secondary"
							:color="editedIsAdditional ? 'grey' : 'primary'"
							:allowed-dates="checkAvailability()"
							:picker-date.sync="displayedDateOnCreateDatePicker"
						>
							<v-btn
								rounded
								class="px-6 mx-auto"
								color="primary"
								depressed
								@click="addRange(newRange)"
							>
								Добавить
							</v-btn>
						</v-date-picker>
					</v-list-group>
				</v-list>
			</v-card-text>
			<v-card-actions>
				<v-row class="pa-0 ma-0">
					<v-col cols="12">
						<div
							class="d-flex align-center justify-center"
							style="gap: 4px"
						>
							<v-chip
								v-if="countMain"
								outlined
								small
								label
								color="primary"
								class="my-1"
							>
								Основной отпуск: {{ countMain }} {{ getDaysTitle(countMain) }}
							</v-chip>
							<v-chip
								v-if="countAdditional"
								outlined
								small
								label
								class="my-1"
							>
								Дополнительный отпуск: {{ countAdditional }} {{ getDaysTitle(countAdditional) }}
							</v-chip>
						</div>
					</v-col>
					<v-col
						class="pa-0 px-1"
						:cols="submitPossible ? 6 : 12"
					>
						<v-btn
							rounded
							block
							small
							depressed
							:disabled="submitting"
							@click="modelDialog = false"
						>
							Закрыть
						</v-btn>
					</v-col>
					<v-col
						cols="6"
						class="pa-0 px-1"
					>
						<v-btn
							v-if="submitPossible"
							rounded
							block
							small
							color="primary"
							depressed
							:loading="submitting"
							@click="submit"
						>
							{{ btnText }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style scoped lang="scss">
.opened_list_group {
	border: thin solid rgba(0, 0, 0, 0.12);
	border-radius: 4px;
}
</style>